
  import { defineComponent, onMounted, ref, computed, reactive } from 'vue';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';

  import { reinitializeComponents } from '@/core/plugins/keenthemes';
  import moment from 'moment';

  export default defineComponent({
    name: 'registered-trades-reports',
    components: {},
    async setup() {
      const showExportBtn = ref(false);
      const data = ref();
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const { can } = useAbility();
      const selectedItems = ref<any>({
        dateFrom: '',
        dateTo: '',
        areaId: '',
        priceFrom: undefined,
        priceTo: undefined,
        size: undefined,
        propertySoldStatus: undefined,
        propertyStatusId: '',
        propertyTypeId: '',
      });

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const goBack = () => {
        router.back();
      };

      // await store.dispatch(Actions.GET_REGISTERED_TRADE_REPORTS);

      // data.value = store.getters.registeredTradesDashboard;

      const onSubmitCreate = async () => {
        if (selectedItems.value.dateFrom) {
          selectedItems.value.dateFrom = moment(
            selectedItems.value.dateFrom
          ).format('YYYY-MM-DD');
        }
        if (selectedItems.value.dateTo) {
          selectedItems.value.dateTo = moment(
            selectedItems.value.dateTo
          ).format('YYYY-MM-DD');
        }

        debugger;
        console.log(selectedItems);

        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }
        // await store.dispatch(Actions.GET_REGISTERED_TRADE_REPORTS, values);
        // data.value = store.getters.registeredTradesReports;
        debugger;
        await store.dispatch(
          Actions.GET_REGISTERED_TRADE_REPORTS,
          selectedItems.value
        );

        data.value = store.getters.registeredTradesReports;

        if (data.value.data.length > 0) showExportBtn.value = true;

        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_GET_REPORTS_DATA'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            //Deactivate indicator
            submitButton.value?.removeAttribute('data-kt-indicator');
            // eslint-disable-next-line
            submitButton.value!.disabled = false;

            // router.push({ name: 'ad-types-listing' });
          });
        }
      };

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('registeredTradesReports'), [
          translate('Reports'),
        ]);
      });
      await store.dispatch(Actions.GET_ALL_AREAS_LIST, {
        type: 'registeredTrades',
      });
      await store.dispatch(Actions.GET_ALL_PROPERTY_TYPE, {
        type: 'registeredTrades',
      });
      await store.dispatch(Actions.GET_REGISTERED_TRADES_DESC);

      const excelHeader = reactive({
        'Transaction Number': 'transactionNumber',
        Area: 'areaName',
        Size: 'size',
        description: 'descriptionAr',
        'Property Type': 'propertyTypeName',
        Price: 'price',
        'Price Per M2': 'pricePerM2',
        Block: 'block',
        Percentage: 'percentage',
        Notes: 'notesAr',
        'Transaction Date': {
          field: 'transactionDate',
          callback: (value) => {
            return value ? new Date(value).toISOString().slice(0, 10) : '';
          },
        },
      });

      reinitializeComponents();
      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-over');

      return {
        excelHeader,
        dateNow: new Date().toISOString().slice(0, 10),
        showExportBtn,
        data,
        selectedItems,
        areas: computed(() => store.getters.allListAreas),
        propertyTypes: computed(() => store.getters.propertyTypesAllList),
        registeredTradesDesc: computed(
          () => store.getters.getregisteredTradesDesc
        ),
        onSubmitCreate,
        submitButton,
        translate,
        goBack,
        can,
      };
    },
  });
